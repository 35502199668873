import { Link, SpinnerOverlay } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import { isNil } from '@sixfold/typed-primitives';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { CompanyPicker } from '../../components/company_picker';
import { NavigateButton } from '../../components/company_picker/NavigateButton';
import { MapPageFrame } from '../../components/map_page_frame';
import { fetchJSON } from '../../lib/util';

import styles from './CompanyPicker.module.css';

interface Company {
  company_id: string;
  company_name: string;
  is_shipper: boolean;
  is_carrier: boolean;
}

export const useUserCompanies = () => {
  const [companies, setCompanies] = React.useState<Company[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const fetchUserCompanies = async () => {
      try {
        const companyData = await fetchJSON<Company[]>('/user_companies');
        setCompanies(companyData);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserCompanies().catch((err) => console.error(err));
  }, []);

  return { companies, isLoading };
};

export const CompanyPickerContainer: React.FC = () => {
  const { companies, isLoading } = useUserCompanies();

  const [selectedCompany, setSelectedCompany] = React.useState<Company | undefined>(companies?.[0]);
  const shouldShowCompanyPicker = React.useMemo(() => companies?.length > 1, [companies]);

  React.useEffect(() => {
    if (isNil(companies) || companies?.length === 0) {
      return;
    }

    setSelectedCompany(companies[0]);
  }, [companies]);

  if (isLoading) {
    return <SpinnerOverlay />;
  }

  return (
    <>
      <Localized id="company_picker.page.title" defaultValue="Visibility Hub - Select Company">
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </Localized>
      <MapPageFrame
        title={
          shouldShowCompanyPicker ? (
            <Localized id="component.company_picker.title">Select a company</Localized>
          ) : undefined
        }
        className={{
          content: shouldShowCompanyPicker ? undefined : styles.content,
        }}
        footer={
          <Localized id="company_picker.footer.needHelp.button">
            Need help?{' '}
            <Link
              href="https://support.transporeon.com/servicedesk/customer/portal/16/create/474"
              target="_blank"
              rel="noopener noreferrer nofollow">
              Contact us.
            </Link>
          </Localized>
        }>
        {shouldShowCompanyPicker ? (
          <CompanyPicker
            companies={companies}
            onChange={(company) => setSelectedCompany(company)}
            selectedCompany={selectedCompany}>
            <NavigateButton companyId={selectedCompany?.company_id} />
          </CompanyPicker>
        ) : (
          <NavigateButton companyId={selectedCompany?.company_id} />
        )}
      </MapPageFrame>
    </>
  );
};
